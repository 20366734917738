import { forwardRef } from "react";

import { cn } from "../../utils/cn";
import { Pagination } from "../pagination/Pagination";
import { Skeleton } from "../skeleton/Skeleton";

import type {
  ComponentPropsWithoutRef,
  FC,
  HTMLAttributes,
  TdHTMLAttributes,
  ThHTMLAttributes,
} from "react";
import type {
  ComponentWithAttachedSkeletonComponent,
  ForwardRefComponentWithSubcomponents,
} from "../../utils/types";
import type { PaginationProps } from "../pagination/Pagination";

interface Props extends ComponentPropsWithoutRef<"table"> {
  containerClassName?: string;
}
export const RawTable = forwardRef<HTMLTableElement, Props>(
  ({ className, containerClassName, ...props }, ref) => (
    <div
      className={cn(
        "w-full overflow-auto rounded border border-solid border-gray-100 bg-white @container dark:border-gray-800 dark:bg-gray-900",
        containerClassName
      )}
    >
      <table ref={ref} className={cn("w-full caption-bottom text-sm", className)} {...props} />
    </div>
  )
) as ForwardRefComponentWithSubcomponents<
  HTMLTableElement,
  {
    Header: typeof TableHeader;
    Body: typeof TableBody;
    Footer: typeof TableFooter;
    Row: typeof TableRow;
    Head: typeof TableHead;
    Cell: typeof TableCell;
    Caption: typeof TableCaption;
    Pagination: typeof TablePagination;
  },
  Props
>;
RawTable.displayName = "RawTable";

const TableHeader = forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <thead
    ref={ref}
    className={cn(
      "bg-gray-20 dark:bg-gray-850 [&_tr]:border-x-0 [&_tr]:border-b [&_tr]:border-t-0 [&_tr]:border-solid [&_tr]:border-b-gray-100 dark:[&_tr]:border-b-gray-800",
      className
    )}
    {...props}
  />
));
TableHeader.displayName = "RawTable.Header";
RawTable.Header = TableHeader;

const TableBody = forwardRef<HTMLTableSectionElement, HTMLAttributes<HTMLTableSectionElement>>(
  ({ className, ...props }, ref) => (
    <tbody ref={ref} className={cn("[&_tr:last-child]:border-0", className)} {...props} />
  )
);
TableBody.displayName = "RawTable.Body";
RawTable.Body = TableBody;

const TableFooter = forwardRef<HTMLTableSectionElement, HTMLAttributes<HTMLTableSectionElement>>(
  ({ className, ...props }, ref) => (
    <tfoot
      ref={ref}
      className={cn(
        "border-x-0 border-b-0 border-t border-solid border-gray-100 font-medium dark:border-gray-800 [&_tr]:border-0",
        className
      )}
      {...props}
    />
  )
);
TableFooter.displayName = "RawTable.Footer";
RawTable.Footer = TableFooter;

const TableRow = forwardRef<HTMLTableRowElement, HTMLAttributes<HTMLTableRowElement>>(
  ({ className, ...props }, ref) => (
    <tr
      ref={ref}
      className={cn(
        "data-[state=selected]:bg-muted border-x-0 border-b border-t-0 border-solid border-gray-100 transition-colors dark:border-gray-800",
        className
      )}
      {...props}
    />
  )
);
TableRow.displayName = "RawTable.Row";
RawTable.Row = TableRow;

const TableHead = forwardRef<HTMLTableCellElement, ThHTMLAttributes<HTMLTableCellElement>>(
  ({ className, ...props }, ref) => (
    <th
      ref={ref}
      className={cn(
        "relative p-3 text-left align-middle font-semibold leading-6 after:absolute after:inset-y-3 after:right-0 after:w-px after:bg-gray-100 after:content-[''] last:after:content-none after:dark:border-gray-800 [&:has([role=checkbox])]:pr-0",
        className
      )}
      {...props}
    />
  )
);
TableHead.displayName = "RawTable.Head";
RawTable.Head = TableHead;

const TableCell = forwardRef<HTMLTableCellElement, TdHTMLAttributes<HTMLTableCellElement>>(
  ({ className, ...props }, ref) => (
    <td
      ref={ref}
      className={cn(
        "overflow-hidden text-ellipsis p-3 align-middle text-sm leading-6 [&:has([role=checkbox])]:pr-0",
        className
      )}
      {...props}
    />
  )
) as ComponentWithAttachedSkeletonComponent<
  HTMLTableCellElement,
  TdHTMLAttributes<HTMLTableCellElement>
>;
TableCell.displayName = "RawTable.Cell";
RawTable.Cell = TableCell;

const TableCellSkeleton: FC<HTMLAttributes<HTMLTableCellElement>> = ({ className, ...props }) => (
  <td className={cn("px-2 py-3", className)} {...props}>
    <Skeleton className="h-8 w-full" />
  </td>
);
TableCellSkeleton.displayName = "RawTable.Cell.Skeleton";
TableCell.Skeleton = TableCellSkeleton;

const TableCaption = forwardRef<HTMLTableCaptionElement, HTMLAttributes<HTMLTableCaptionElement>>(
  ({ className, ...props }, ref) => (
    <caption ref={ref} className={cn("text-muted-foreground mt-4 text-sm", className)} {...props} />
  )
);
TableCaption.displayName = "RawTable.Caption";
RawTable.Caption = TableCaption;

const TablePagination: FC<PaginationProps> & { Skeleton: FC } = props => (
  <TableCaption
    className={cn(
      "mt-0 border-x-0 border-b-0 border-t border-solid border-gray-100 py-1.5 pl-3 pr-1.5 dark:border-gray-800"
    )}
  >
    <Pagination {...props} />
  </TableCaption>
);
TablePagination.displayName = "RawTable.Pagination";
RawTable.Pagination = TablePagination;

const TablePaginationSkeleton: FC = () => (
  <TableCaption
    className={cn(
      "mt-0 border-x-0 border-b-0 border-t border-solid border-gray-100 px-2 py-1.5 dark:border-gray-800"
    )}
  >
    <Pagination.Skeleton />
  </TableCaption>
);
TablePaginationSkeleton.displayName = "RawTable.Pagination.Skeleton";
TablePagination.Skeleton = TablePaginationSkeleton;
