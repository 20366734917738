import { cn } from "../../utils/cn";
import { Logo } from "./Logo";

import type { FC } from "react";

export const SpinnerLogo: FC = () => {
  return (
    <div className="relative flex items-center justify-center">
      <Logo className="absolute size-6 lg:size-[28px]" compact />
      <div
        className={cn(
          "size-24 lg:size-[120px]",
          "border-[6px] border-solid border-blue border-t-transparent dark:border-t-legacyGray-600",
          "animate-spin rounded-full"
        )}
      />
    </div>
  );
};
