import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cloneElement, forwardRef, isValidElement, useCallback, useContext, useMemo } from "react";
import { chain, mergeProps, useButton, useFocusRing, useHover } from "react-aria";
import { useFormContext } from "react-hook-form";
import { tv } from "tailwind-variants";

import { useDomRef } from "../../hooks/useDomRef";
import { filterDOMProps } from "../../utils/filterDomProps";
import { Ripple } from "../ripple/Ripple";
import { useRipple } from "../ripple/useRipple";
import { Skeleton } from "../skeleton/Skeleton";
import { buttonGroupContext } from "./ButtonGroup";

import type {
  ElementType,
  FC,
  HTMLAttributes,
  MouseEventHandler,
  ReactElement,
  ReactNode,
  SVGAttributes,
} from "react";
import type { AriaButtonProps } from "react-aria";
import type { VariantProps } from "tailwind-variants";
import type { ComponentWithAttachedSkeletonComponent } from "../../utils/types";
import type { RippleProps } from "../ripple/Ripple";

export const buttonVariants = tv({
  base: [
    "group",
    "relative",
    "z-0",
    "inline-flex",
    "items-center",
    "justify-center",
    "gap-x-2",
    "cursor-pointer",
    "box-border",
    "text-sm",
    "font-medium",
    "leading-none",
    "whitespace-nowrap",
    "appearance-none",
    "outline-none",
    "select-none",
    "subpixel-antialiased",
    "overflow-hidden",
    "transition-all",
    "data-[focus-visible=true]:outline-none",
    "data-[focus-visible=true]:ring-4",
    "data-[focus-visible=true]:ring-blue-400",
    "data-[focus-visible=true]:ring-opacity-20",
    "data-[focus-visible=true]:ring-offset-0",
    "disabled:pointer-events-none",
    "disabled:opacity-60",
  ],
  variants: {
    variant: {
      default: "border border-solid bg-white dark:bg-gray-800",
      primary: "border-none text-white",
      light: "border-none bg-white dark:bg-gray-900",
      link: "border-none bg-transparent text-blue hover:text-blue-400",
      neutralLink: "border-none bg-transparent text-gray-600 underline",
    },
    size: {
      xs: "h-5 px-1",
      sm: "h-7 px-3",
      md: "h-8 px-4",
      lg: "h-10 px-7",
    },
    destructive: {
      true: "",
      false: "",
    },
    isIconOnly: {
      true: "gap-0 px-0",
      false: "",
    },
    radius: {
      default: "rounded",
      full: "rounded-full",
    },
    // TODO(sam): Add styles to cover all ghost variant combinations, right now it only works well
    // with the light variant
    ghost: {
      true: "bg-transparent opacity-100 hover:bg-transparent hover:opacity-75 dark:bg-transparent",
      false: "",
    },
    isDisabled: {
      true: "pointer-events-none opacity-60",
      false: "",
    },
    fullWidth: {
      true: "w-full",
      false: "",
    },
    isInGroup: {
      true: "[&:not(:first-child):not(:last-child)]:rounded-none",
      false: "",
    },
    disableAnimation: {
      true: "!transition-none",
      false:
        "transition-transform-colors-opacity data-[pressed=true]:scale-[0.97] motion-reduce:transition-none",
    },
  },
  compoundVariants: [
    {
      size: "md",
      isIconOnly: true,
      className: "size-8",
    },
    {
      size: "xs",
      isIconOnly: true,
      className: "size-5",
    },
    {
      size: "sm",
      isIconOnly: true,
      className: "size-7",
    },
    {
      size: "lg",
      isIconOnly: true,
      className: "size-10",
    },
    {
      variant: "default",
      destructive: false,
      className: [
        "border-gray-150",
        "hover:bg-gray-20",
        "text-gray-700",
        "data-[focus-visible=true]:text-blue-600",
        "dark:border-gray-700",
        "dark:text-white",
        "dark:hover:bg-gray-700",
      ],
    },
    {
      variant: "default",
      destructive: true,
      className: [
        "border-red",
        "text-red",
        "hover:bg-red-50",
        "dark:border-red-400",
        "dark:bg-transparent",
        "dark:text-red-400",
        "dark:hover:bg-red/20",
      ],
    },
    {
      variant: "primary",
      destructive: false,
      className: ["bg-blue", "hover:bg-blue-600", "data-[focus-visible=true]:bg-blue-600"],
    },
    {
      variant: "primary",
      destructive: true,
      className: [
        "bg-red",
        "hover:bg-red-600",
        "data-[focus-visible=true]:bg-red-600",
        "dark:bg-red-600",
        "dark:hover:bg-red-700",
        "dark:data-[focus-visible=true]:bg-red-700",
      ],
    },
    {
      variant: "light",
      destructive: false,
      className: "text-gray-700 hover:bg-gray-20 dark:text-white dark:hover:bg-gray-800",
    },
    {
      variant: "light",
      destructive: true,
      className: "text-red hover:bg-red-50 dark:text-red-400 dark:hover:bg-red-700/25",
    },
    {
      isInGroup: true,
      radius: "default",
      className: "rounded-none first:rounded-l last:rounded-r",
    },
    {
      isInGroup: true,
      radius: "full",
      className: "rounded-none first:rounded-l-full last:rounded-r-full",
    },
    {
      variant: "link",
      size: "xs",
      className: "text-xs",
    },
  ],
  defaultVariants: {
    variant: "default",
    size: "md",
    radius: "default",
    isDisabled: false,
    isIconOnly: false,
    destructive: false,
    ghost: false,
    isInGroup: false,
    fullWidth: false,
    disableAnimation: false,
  },
});

export type ButtonVariantProps = VariantProps<typeof buttonVariants>;

export interface ButtonBaseProps extends HTMLAttributes<HTMLButtonElement> {
  as?: ElementType;
  /**
   * Whether the button should display a ripple effect on press.
   * @default false
   */
  disableRipple?: boolean;
  /**
   * The button start content.
   */
  startContent?: ReactNode;
  /**
   * The button end content.
   */
  endContent?: ReactNode;
  /**
   * Spinner to display when loading.
   */
  spinner?: ReactNode;
  /**
   * The spinner placement.
   * @default "start"
   */
  spinnerPlacement?: "start" | "end";
  /**
   * Whether the button should display a loading spinner.
   * @default false
   */
  isLoading?: boolean;
  /**
   * The native button click event handler.
   * use `onPress` instead.
   */
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

export type ButtonProps = ButtonBaseProps &
  Omit<AriaButtonProps, keyof ButtonVariantProps> &
  Omit<ButtonVariantProps, "isInGroup">;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const groupContext = useContext(buttonGroupContext);
  const isInGroup = !!groupContext;

  const {
    as,
    children,
    startContent: startContentProp,
    endContent: endContentProp,
    autoFocus,
    className,
    spinner = <FontAwesomeIcon icon={faSpinnerThird} spin />,
    fullWidth = groupContext?.fullWidth ?? false,
    size = groupContext?.size ?? "md",
    variant = groupContext?.variant ?? "default",
    ghost,
    destructive,
    disableAnimation = groupContext?.disableAnimation ?? false,
    radius = groupContext?.radius,
    disableRipple = groupContext?.disableRipple ?? false,
    isDisabled: isDisabledProp = groupContext?.isDisabled ?? false,
    isIconOnly = groupContext?.isIconOnly ?? false,
    isLoading = false,
    spinnerPlacement = "start",
    onPress,
    onClick,
    ...otherProps
  } = props;

  // TODO(sam): Find out how to make TypeScript not complain about this
  // const Component = as || "button";
  // const shouldFilterDOMProps = typeof Component === "string";

  const domRef = useDomRef(ref);

  const { isFocusVisible, isFocused, focusProps } = useFocusRing({
    autoFocus,
  });

  const form = useFormContext();
  const formInvalid = form ? props.type === "submit" && !form.formState.isValid : false;

  const isDisabled = isDisabledProp || isLoading || formInvalid;

  const styles = useMemo(
    () =>
      buttonVariants({
        size,
        variant,
        ghost,
        radius,
        destructive,
        fullWidth,
        isDisabled,
        isInGroup,
        disableAnimation,
        isIconOnly,
        className,
      }),
    [
      size,
      variant,
      ghost,
      radius,
      destructive,
      fullWidth,
      isDisabled,
      isInGroup,
      isIconOnly,
      disableAnimation,
      className,
    ]
  );

  const { onClick: onRippleClickHandler, onClear: onClearRipple, ripples } = useRipple();

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (disableRipple || isDisabled || disableAnimation) {
        return;
      }
      if (domRef.current) {
        onRippleClickHandler(e);
      }
    },
    [disableRipple, isDisabled, disableAnimation, domRef, onRippleClickHandler]
  );

  const { buttonProps: ariaButtonProps, isPressed } = useButton(
    {
      // elementType: as,
      isDisabled,
      onPress,
      onClick: chain(onClick, handleClick),
      ...otherProps,
    } as AriaButtonProps,
    domRef
  );

  const { isHovered, hoverProps } = useHover({ isDisabled });

  const buttonProps = useMemo(
    () => ({
      "data-disabled": isDisabled,
      "data-focus": isFocused,
      "data-pressed": isPressed,
      "data-focus-visible": isFocusVisible,
      "data-hover": isHovered,
      "data-loading": isLoading,
      ...mergeProps(
        ariaButtonProps,
        focusProps,
        hoverProps,
        filterDOMProps(otherProps, {
          // enabled: shouldFilterDOMProps,
        })
      ),
    }),
    [
      isLoading,
      isDisabled,
      isFocused,
      isPressed,
      // shouldFilterDOMProps,
      isFocusVisible,
      isHovered,
      ariaButtonProps,
      focusProps,
      hoverProps,
      otherProps,
    ]
  );

  const getIconClone = (icon: ReactNode) =>
    isValidElement(icon)
      ? cloneElement(icon as ReactElement<SVGAttributes<SVGElement>>, {
          "aria-hidden": true,
          focusable: false,
          tabIndex: -1,
        })
      : null;

  const startContent = getIconClone(startContentProp);
  const endContent = getIconClone(endContentProp);

  const rippleProps = useMemo<RippleProps>(
    () => ({ ripples, onClear: onClearRipple }),
    [ripples, onClearRipple]
  );

  return (
    <button ref={domRef} className={styles} {...buttonProps}>
      {startContent}
      {isLoading && spinnerPlacement === "start" && spinner}
      {isLoading && isIconOnly ? null : children}
      {isLoading && spinnerPlacement === "end" && spinner}
      {endContent}
      {!disableRipple && <Ripple {...rippleProps} />}
    </button>
  );
}) as ComponentWithAttachedSkeletonComponent<HTMLButtonElement, ButtonProps, ButtonSkeletonProps>;

Button.displayName = "Button";

const buttonSkeletonVariants = tv({
  variants: {
    size: {
      default: "h-8 w-24",
      sm: "h-7 w-16",
      lg: "h-10 w-32",
    },
    isIconOnly: {
      true: "size-8",
      false: "",
    },
  },
  defaultVariants: {
    size: "default",
    isIconOnly: false,
  },
});

interface ButtonSkeletonProps
  extends HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof buttonSkeletonVariants> {}

const ButtonSkeleton: FC<ButtonSkeletonProps> = ({ className, size, isIconOnly, ...props }) => (
  <Skeleton className={buttonSkeletonVariants({ size, isIconOnly, className })} {...props} />
);

Button.Skeleton = ButtonSkeleton;
