import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo, useRef } from "react";
import { tv } from "tailwind-variants";

import { cn } from "../../utils/cn";
import { Button } from "../button/Button";
import { useComboBox } from "../combobox/useComboBox";
import { ScrollShadow } from "../extra/ScrollShadow";
import { Input } from "../input/Input";
import { ListBox } from "../listbox/ListBox";
import { Popover } from "../popover/Popover";
import { PopoverContent } from "../popover/PopoverContent";
import { PopoverTrigger } from "../popover/PopoverTrigger";
import { Tooltip } from "../tooltip/Tooltip";

import type { UseComboBoxProps } from "../combobox/useComboBox";

const selectFilterVariants = tv({
  slots: {
    icon: [
      "ease text-xs transition-transform duration-150 group-aria-expanded:rotate-180 motion-reduce:transition-none",
    ],
    trigger: [
      "group-data-[selected=true]:border-blue-150",
      "group-data-[selected=true]:bg-blue-100",
      "group-data-[selected=true]:font-semibold",
      "group-data-[selected=true]:text-blue",
      "group-data-[selected=true]:hover:bg-blue-150",
      "dark:group-data-[selected=true]:border-blue-700",
      "dark:group-data-[selected=true]:bg-blue-800",
      "dark:group-data-[selected=true]:text-blue-200",
      "dark:group-data-[selected=true]:hover:bg-blue-700",
    ],
    selectedValue: "max-w-0 truncate leading-normal",
    tooltipContent: "flex flex-col gap-1",
    popoverContent: "flex w-72 gap-1 p-0",
    listBoxWrapper: "max-h-48 px-1",
    popoverHeader: "flex w-full flex-col gap-2 px-2 pt-2",
    popoverFooter: [
      "w-full",
      "px-4",
      "py-2",
      "border",
      "border-solid",
      "border-t-gray-50",
      "dark:border-t-gray-800",
      "border-l-0",
      "border-b-0",
      "border-r-0",
      "flex",
      "items-center",
      "gap-3.5",
    ],
    selectedCountBadge: [
      "bg-blue-150",
      "text-blue",
      "dark:bg-blue-700",
      "dark:text-blue-200",
      "rounded-sm",
      "text-sm",
      "font-semibold",
      "p-1",
      "leading-none",
    ],
  },
  variants: {
    showSelectedValue: {
      true: {
        selectedValue: "max-w-[96px]",
        trigger: "group-data-[selected=true]:px-2",
      },
      false: {
        selectedValue: "max-w-0",
      },
    },
    fullWidth: {
      true: {
        trigger: "w-full",
      },
    },
  },
});

export interface SelectFilterProps<T> extends UseComboBoxProps<T> {
  filterLabel: string;
  searchPlaceholder?: string;
  /**
   * Show the selected value in the trigger button. If there are multiple
   * selected values, only the first value will be shown, together with a
   * count of the remaining selected values.
   */
  showSelectedValue?: boolean;
  /**
   * Show the total count of selected values in the trigger
   * button. This will only be shown if `showSelectedValue` is `false`.
   */
  showSelectedCount?: boolean;
  isSearchable?: boolean;
}

const SelectFilterImpl = <T extends object>({
  filterLabel,
  searchPlaceholder = "Search",
  showSelectedValue = true,
  showSelectedCount = false,
  isSearchable = true,
  fullWidth = false,
  ...props
}: SelectFilterProps<T>) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const triggerRef = useRef<HTMLButtonElement>(null);
  const selectedValueRef = useRef<HTMLSpanElement>(null);

  const {
    isOpen,
    selectionMode,
    state,
    baseProps,
    inputProps,
    listBoxProps,
    listBoxWrapperProps,
    popoverProps,
  } = useComboBox({
    ...props,
    ref: inputRef,
    triggerRef,
    onOpenChange: open => {
      if (open) {
        inputRef.current?.focus();
      }
      props.onOpenChange?.(open);
    },
    fullWidth,
    "aria-label": filterLabel,
  });

  const slots = selectFilterVariants({ showSelectedValue, fullWidth });

  const isSelectedValueTruncated = useMemo(() => {
    if (!selectedValueRef.current || state.selectedItems?.length !== 1) {
      return false;
    }
    return selectedValueRef.current.scrollWidth > selectedValueRef.current.clientWidth;
  }, [state.selectedItems]);

  const showTooltip = showSelectedValue
    ? isSelectedValueTruncated || state.selectedKeys.size > 1
    : state.selectedKeys.size > 0;

  return (
    <div {...baseProps} data-selected={state.selectedKeys.size > 0}>
      <Popover isOpen={isOpen} {...popoverProps} placement="bottom-start">
        <Tooltip
          isDisabled={!showTooltip}
          color="default"
          content={
            <div className={slots.tooltipContent()}>
              {state.selectedItems?.map(item => (
                <span key={item.key}>{item.textValue || item.key}</span>
              ))}
            </div>
          }
        >
          <div className={cn(fullWidth ? "w-full" : "max-w-fit")}>
            <PopoverTrigger>
              <Button
                className={slots.trigger()}
                endContent={
                  <span className={slots.icon()}>
                    <FontAwesomeIcon icon={faChevronDown} />
                  </span>
                }
                isDisabled={props.isDisabled}
                ref={triggerRef}
              >
                <span className={cn({ "mr-auto": fullWidth })}>{filterLabel}</span>
                {(showSelectedValue || showSelectedCount) && (
                  <span
                    className={cn(
                      "gap-1 font-normal",
                      state.selectedItems && !!state.selectedItems.length
                        ? "inline-flex items-center"
                        : "hidden"
                    )}
                  >
                    {showSelectedValue && (
                      <span className={slots.selectedValue()} ref={selectedValueRef}>
                        {state.selectedItems?.[0]?.textValue}
                      </span>
                    )}
                    {showSelectedValue && state.selectedKeys.size > 1 && (
                      <span>+{state.selectedKeys.size - 1}</span>
                    )}
                    {showSelectedCount && !showSelectedValue && state.selectedKeys.size > 0 && (
                      <span className={slots.selectedCountBadge()}>{state.selectedKeys.size}</span>
                    )}
                  </span>
                )}
              </Button>
            </PopoverTrigger>
          </div>
        </Tooltip>
        <PopoverContent className={slots.popoverContent()}>
          {isSearchable && (
            <div className={slots.popoverHeader()}>
              <Input.Search
                {...inputProps}
                classNames={undefined} // Don't need special combobox styles since we don't have a tag list
                placeholder={searchPlaceholder}
              />
            </div>
          )}
          <ScrollShadow
            {...listBoxWrapperProps}
            className={cn(listBoxWrapperProps.className, slots.listBoxWrapper())}
          >
            <ListBox {...listBoxProps} />
          </ScrollShadow>
          {(selectionMode === "multiple" || state.selectedKeys.size > 0) && (
            <div className={slots.popoverFooter()}>
              {selectionMode === "multiple" && (
                <span className="text-sm">{state.selectedKeys.size} Selected</span>
              )}
              {state.selectedKeys.size > 0 && (
                <Button
                  variant="link"
                  size="xs"
                  className="text-sm"
                  onClick={() => {
                    state.setSelectedKeys(new Set());
                  }}
                >
                  Clear
                </Button>
              )}
            </div>
          )}
        </PopoverContent>
      </Popover>
    </div>
  );
};

export const SelectFilter = SelectFilterImpl as typeof SelectFilterImpl & {
  Item: typeof ListBox.Item;
  Section: typeof ListBox.Section;
};

SelectFilter.Item = ListBox.Item;
SelectFilter.Section = ListBox.Section;
