import { forwardRef, useMemo } from "react";
import { tv } from "tailwind-variants";

import { cn } from "../../utils/cn";
import { createContext } from "../../utils/createContext";

import type { ComponentPropsWithoutRef, ReactNode } from "react";
import type { VariantProps } from "tailwind-variants";
import type { ForwardRefComponentWithSubcomponents, SlotsToClasses } from "../../utils/types";

const descriptionListVariants = tv({
  slots: {
    base: "mb-0 divide-x-0 divide-y divide-solid divide-gray-50 dark:divide-gray-800",
    item: "grid grid-cols-2 gap-4 py-2",
    itemTitle: "mb-0 flex items-center text-sm font-semibold text-gray-500 dark:text-gray-400",
    itemDescription: "mb-0 text-sm text-gray-850 dark:text-white",
  },
});

export interface DescriptionListProps
  extends ComponentPropsWithoutRef<"dl">,
    VariantProps<typeof descriptionListVariants> {
  classNames?: SlotsToClasses<keyof ReturnType<typeof descriptionListVariants>>;
}

const useDescriptionList = ({ className, classNames, ...props }: DescriptionListProps) => {
  const slots = useMemo(() => descriptionListVariants(), []);

  const baseProps = {
    "data-slot": "base",
    className: slots.base({ className: cn(className, classNames?.base) }),
    ...props,
  };

  return {
    baseProps,
    slots,
    classNames,
  };
};

type DescriptionListContext = Omit<ReturnType<typeof useDescriptionList>, "baseProps">;

const [DescriptionListProvider, useDescriptionListContext] = createContext<DescriptionListContext>({
  name: "DescriptionList",
});

export const DescriptionList = forwardRef<HTMLDListElement, DescriptionListProps>((props, ref) => {
  const { baseProps, ...context } = useDescriptionList(props);

  return (
    <DescriptionListProvider value={context}>
      <dl ref={ref} {...baseProps} />
    </DescriptionListProvider>
  );
}) as ForwardRefComponentWithSubcomponents<
  HTMLDListElement,
  {
    Item: typeof DescriptionListItem;
  },
  DescriptionListProps
>;
DescriptionList.displayName = "DescriptionList";

export interface DescriptionListItemProps extends Omit<ComponentPropsWithoutRef<"div">, "title"> {
  title: ReactNode;
  wrapperClassName?: string;
  titleClassName?: string;
}

const DescriptionListItem = forwardRef<HTMLDivElement, DescriptionListItemProps>(
  ({ className, wrapperClassName, titleClassName, title, children, ...props }, ref) => {
    const { slots, classNames } = useDescriptionListContext();

    return (
      <div
        ref={ref}
        className={slots.item({ className: cn(wrapperClassName, classNames?.item) })}
        {...props}
      >
        <dt className={slots.itemTitle({ className: cn(titleClassName, classNames?.itemTitle) })}>
          {title}
        </dt>
        <dd
          className={slots.itemDescription({
            className: cn(className, classNames?.itemDescription),
          })}
        >
          {children}
        </dd>
      </div>
    );
  }
);
DescriptionListItem.displayName = "DescriptionList.Item";
DescriptionList.Item = DescriptionListItem;
